<template>
  <div class="container mt-3">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app-layout-webview'
}
</script>
