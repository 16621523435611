import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'

import globals from '@/globals'
import store from '@/store'

import serviceRoutes from './service'
import webviewRoutes from './webview'
import adminRoutes from './admin'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/home' }
]
  .concat(serviceRoutes)
  .concat(webviewRoutes)
  .concat(adminRoutes)

// 404 Not Found page
ROUTES.push({
  path: '*',
  component: NotFound
})

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: ROUTES
})

// Global After Hooks
router.afterEach((to, from) => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  if (to.matched.some(record => record.meta.unauthorized)) {
    next()
  } else {
    if (store.getters.isAuthenticated) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
