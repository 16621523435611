<template>
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <app-layout-sidenav />

      <div class="layout-container">
        <app-layout-navbar />

        <div class="layout-content">
          <div class="router-transitions container-fluid flex-grow-1 container-p-y">
            <router-view />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="layout-overlay" @click="closeSidenav"></div>
    <b-button id="button-top" size="lg" variant="outline-secondary icon-btn rounded-pill" class="d-block mb-2" @click="scrollTop(0, 0)"><i class="fas fa-arrow-up"></i></b-button>
  </div>
</template>

<script>
import Vue from 'vue'
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  name: 'app-layout-2',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter
  },

  created () {
    this.autoLogin()
  },

  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy () {
    this.layoutHelpers.destroy()
  },

  methods: {
    autoLogin () {
      // 로그인 후 내 정보를 확인하고, SendBird Connection을 생성한다.
      this.$store.dispatch('AUTO_LOGIN')
        .then((data) => {
          console.log('AUTO_LOGIN')
        })
        .catch(({ message }) => {
          // console.error('AUTO_LOGIN', message)

          if (this.$route.matched.some(record => record.meta.unauthorized)) {
            // this.guestToken()
          } else {
            this.$router.push('/logout')
          }
        })
    },
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
