import { SENDBIRD_APP_ID as appId } from './const'

const SendBird = require('sendbird')
const sb = new SendBird({
  appId: appId
})
const ChannelHandler = new sb.ChannelHandler()
const ConnectionHandler = new sb.ConnectionHandler()
let prevMessageListQuery = null

const uuid4 = () => {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export default {
  login (userId) {
    return new Promise((resolve, reject) => {
      return sb.connect(userId.toString(), (user, error) => {
        if (error) reject(error)
        resolve(user)
      })
    })
  },

  getChannel (channelUrl) {
    return new Promise((resolve, reject) => {
      return sb.OpenChannel.getChannel(channelUrl, (channel, error) => {
        if (error) reject(error)
        channel.enter()
        resolve(channel)
      })
    })
  },

  exitChannel (channelUrl) {
    return new Promise((resolve, reject) => {
      return sb.OpenChannel.getChannel(channelUrl, (channel, error) => {
        if (error) reject(error)
        channel.exit()
        resolve(channel)
      })
    })
  },

  getChannelMessages (channel, messageNumber) {
    const messageListQuery = channel.createPreviousMessageListQuery()
    messageNumber = messageNumber || 10

    return new Promise((resolve, reject) => {
      return messageListQuery.load(messageNumber, false, (messageList, error) => {
        if (error) reject(error)
        resolve(messageList)
      })
    })
  },

  getPreviousMessages (channel, earliestMessageTimestamp, limit) {
    const messageListQuery = channel.createMessageListQuery()

    return new Promise((resolve, reject) => {
      return messageListQuery.prev(earliestMessageTimestamp, limit, false, (messageList, error) => {
        if (error) reject(error)
        resolve(messageList)
      })
    })
  },

  getChannelList () {
    const openChannelListQuery = sb.OpenChannel.createOpenChannelListQuery()

    return new Promise((resolve, reject) => {
      return openChannelListQuery.next((channels, error) => {
        if (error) reject(error)
        resolve(channels)
      })
    })
  },

  getChannelUsers (channel) {
    const participantListQuery = channel.createParticipantListQuery()

    return new Promise((resolve, reject) => {
      return participantListQuery.next((participantList, error) => {
        if (error) reject(error)
        resolve(participantList)
      })
    })
  },

  sendMessage (channel, obj) {
    const params = new sb.UserMessageParams()
    params.message = obj.message
    params.customType = obj.customType
    if (obj.data) {
      params.data = JSON.stringify(obj.data)
    }

    return new Promise((resolve, reject) => {
      return channel.sendUserMessage(params, (message, error) => {
        if (error) reject(error)
        resolve(message)
      })
    })
  },

  addConnectionEventHandler () {
    // console.info('SendBird => addConnectionEventHandler')

    ConnectionHandler.onReconnectStarted = () => {
      console.info('SendBird => onReconnectStarted')
    }

    ConnectionHandler.onReconnectSucceeded = () => {
      console.debug('SendBird => onReconnectSucceeded')
    }

    ConnectionHandler.onReconnectFailed = () => {
      console.error('SendBird => onReconnectFailed')
    }

    sb.addConnectionHandler(uuid4(), ConnectionHandler)
  },

  onMessageReceived (cb) {
    ChannelHandler.onMessageReceived = (channel, message) => {
      cb(channel, message)
    }
    sb.addChannelHandler(appId, ChannelHandler)
  },
  onTypingStatusUpdated (cb) {
    ChannelHandler.onTypingStatusUpdated = (channel) => {
      cb(channel, channel.getTypingMembers())
    }
    sb.addChannelHandler(appId, ChannelHandler)
  },

  findGroupChannel (senderId, receiverId) {
    const filteredQuery = sb.GroupChannel.createMyGroupChannelListQuery()
    filteredQuery.userIdsExactFilter = [senderId.toString(), receiverId.toString()]

    return new Promise((resolve, reject) => {
      return filteredQuery.next((groupChannels, error) => {
        if (error) reject(error)
        resolve(groupChannels)
      })
    })
  },
  createGroupChannel (users) {
    const userIds = []
    let NAME = ''
    let COVER_IMAGE_URL = ''
    const DATA = ''

    users.forEach((user, idx) => {
      userIds.push(user.userId.toString())
      NAME += (idx === 0 ? '' : ', ') + user.name

      if (idx === 0) {
        COVER_IMAGE_URL = user.imgUrl
      }
    })

    return new Promise((resolve, reject) => {
      return sb.GroupChannel.createChannelWithUserIds(userIds, true, NAME, COVER_IMAGE_URL, DATA, (groupChannel, error) => {
        if (error) reject(error)
        console.log('4')
        resolve(groupChannel)
      })
    })
  },
  getGroupChannelList (userId) {
    const groupChannelListQuery = sb.GroupChannel.createMyGroupChannelListQuery()
    groupChannelListQuery.includeEmpty = true
    groupChannelListQuery.order = 'latest_last_message'

    return new Promise((resolve, reject) => {
      return groupChannelListQuery.next((channels, error) => {
        if (error) reject(error)
        resolve(channels)
      })
    })
  },
  getGroupChannelName (channel, userId) {
    let name = ''
    channel.members.forEach((member, index) => {
      if (member.userId !== userId.toString()) {
        if (name) {
          name += ', ' + member.nickname
        } else {
          name = member.nickname
        }
      }
    })
    return name
  },
  enterGroupChannel (channelUrl) {
    return new Promise((resolve, reject) => {
      return sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) reject(error)
        resolve(groupChannel)
      })
    })
  },
  getGroupChannelMessageList (channel, messageNumber) {
    prevMessageListQuery = channel.createPreviousMessageListQuery()
    // prevMessageListQuery.limit = LIMIT
    // prevMessageListQuery.reverse = REVERSE
    prevMessageListQuery.includeMetaArray = false

    return new Promise((resolve, reject) => {
      return prevMessageListQuery.load((messages, error) => {
        if (error) reject(error)
        resolve(messages)
      })
    })
  },
  getGroupChannelPreviousMessageList (limit) {
    return new Promise((resolve, reject) => {
      return prevMessageListQuery.load((messages, error) => {
        if (error) reject(error)
        resolve(messages)
      })
    })
  }
}
