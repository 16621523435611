<template>
  <div class="">
    <div class="layout-wrapper">
      <div class="layout-inner layout-navbar-fixed">
        <app-layout-navbar />

        <div class="layout-container">
          <div class="layout-content">
            <div class="router-transitions container flex-grow-1 container-p-y">
              <router-view />
            </div>

            <app-layout-footer />
          </div>
        </div>
      </div>
      <div class="layout-overlay" @click="closeSidenav"></div>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>
    <b-button id="button-top" size="lg" variant="outline-secondary icon-btn rounded-pill" class="d-block mb-2" @click="scrollTop(0, 0)"><i class="fas fa-arrow-up"></i></b-button>
  </div>
</template>

<style src="@/vendor/libs/vue-dropzone/vue-dropzone.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>

<style>
.layout-navbar-fixed .layout-container { margin-top: 4.5rem }
.ui-w-200 { width: 200px; }
.ui-w-250 { width: 250px; }
.ui-w-300 { width: 300px; }
.rounded-1 { border-radius: 1rem !important; }
.channel-cover .thumb0 { margin-top: -0.5rem; }
.channel-cover .thumb1 { margin-left: -1.5rem; margin-bottom: -0.7rem; }

.icon-nav { width: 28px; height: 28px; display: inline-block; background-size: cover;}
.icon-option { width: 18px; height: 18px;; display: inline-block; background-size: cover;}

.icon-search { background-image: url('/img/icons/icon_search_off.png'); }
.icon-home { background-image: url('/img/icons/icon_home_off.png'); }
.icon-browse { background-image: url('/img/icons/icon_browse_off.png'); }
.icon-create { background-image: url('/img/icons/icon_create_off.png'); }
.icon-inbox { background-image: url('/img/icons/icon_share_off.png'); }
.icon-cart { background-image: url('/img/icons/icon_cart_off.png'); }
.icon-heart { background-image: url('/img/icons/icon_heart_off.png'); }
.icon-play { background-image: url('/img/icons/icon_play_off.png'); }
.icon-comment { background-image: url('/img/icons/icon_comment_off.png'); }

.active .icon-search { background-image: url('/img/icons/icon_search_on.png'); }
.active .icon-home { background-image: url('/img/icons/icon_home_on.png'); }
.active .icon-browse { background-image: url('/img/icons/icon_browse_on.png'); }
.active .icon-create { background-image: url('/img/icons/icon_create_on.png'); }
.active .icon-inbox { background-image: url('/img/icons/icon_share_on.png'); }
.active .icon-cart { background-image: url('/img/icons/icon_cart_on.png'); }
.active .icon-heart { background-image: url('/img/icons/icon_heart_active.png'); }
.active .icon-play { background-image: url('/img/icons/icon_play_on.png'); }
.icon-play-active { background-image: url('/img/icons/icon_play_active.png'); width: 35px; height: 35px;}

.icon-heart-white { background-image: url('/img/icons/icon_heart_white.png'); }
.icon-play-white { background-image: url('/img/icons/icon_play_white.png'); }
.icon-comment-white { background-image: url('/img/icons/icon_comment_white.png'); }

.icon-kakaotalk { background-image: url('/img/icons/icon_kakaotalk_320.png'); }

.dropzone { border-width: 1px !important; }
.dz-message { margin: 1rem 0 !important; font-size: 1rem !important; }

.vc-container { width: 300px; }
.vc-text { padding: 1rem 1.5rem; line-height: 1.3rem; font-size: 0.9rem; word-break: keep-all;}
#button-top { position: fixed; bottom: 3rem; right: 3rem;}

.alert-join { border-color: #fdfd65; background-color: #fdfd65; }
</style>

<script>
import Vue from 'vue'
import LayoutNavbar from './LayoutNavbar'
import LayoutFooter from './LayoutFooter'
// import SendBird from '@/services/SendBird'
import { mapState } from 'vuex'

import infiniteScroll from 'vue-infinite-scroll'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import VueCountdown from '@chenfengyuan/vue-countdown'

// Globals
Vue.use(infiniteScroll)

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(VueSocialSharing)

Vue.component(VueCountdown.name, VueCountdown)

export default {
  name: 'app-layout-1',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-footer': LayoutFooter
  },

  computed: {
    ...mapState([
      'me'
    ])
  },

  created () {
    this.autoLogin()

    window.onbeforeunload = function () {
      // SendBird.exitChannel('vuejs')
    }
  },

  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy () {
    this.layoutHelpers.destroy()
  },

  methods: {
    autoLogin () {
      // 로그인 후 내 정보를 확인하고, SendBird Connection을 생성한다.
      this.$store.dispatch('AUTO_LOGIN')
        .then((data) => {
          console.log('AUTO_LOGIN')
        })
        .catch(({ message }) => {
          // console.error('AUTO_LOGIN', message)

          if (this.$route.matched.some(record => record.meta.unauthorized)) {
            // this.guestToken()
          } else {
            this.$router.push('/logout')
          }
        })
    },
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
