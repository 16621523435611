<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo">
        <img src="https://timeattack-dev.s3.ap-northeast-2.amazonaws.com/common/timeattack-i-i-128.png" class="ui-w-40 rounded-circle">
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">TimeAttack</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <!-- Dashboards -->
      <sidenav-router-link icon="ion ion-md-speedometer" to="/cms/dashboard" :exact="true">Dashboard</sidenav-router-link>

      <sidenav-divider class="mb-1"/>

      <sidenav-router-link icon="fas fa-rocket" to="/cms/play" :exact="true">이벤트 목록</sidenav-router-link>
      <sidenav-router-link icon="fas fa-basketball-ball" to="/cms/playing" :exact="true">이벤트 참여</sidenav-router-link>
      <sidenav-router-link icon="fas fa-democrat" to="/cms/product" :exact="true">이벤트 상품</sidenav-router-link>
      <sidenav-router-link icon="fas fa-tag" to="/cms/tag" :exact="true">태그</sidenav-router-link>
      <sidenav-router-link icon="fas fa-flag" to="/cms/report" :exact="true">신고</sidenav-router-link>
      <sidenav-router-link icon="fas fa-envelope" to="/cms/email" :exact="true">메일발송</sidenav-router-link>
      <sidenav-router-link icon="fas fa-user" to="/cms/user" :exact="true">계정관리</sidenav-router-link>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
