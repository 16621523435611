// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import globals from './globals'

import BootstrapVue from 'bootstrap-vue'
import Popper from 'popper.js'
import moment from 'moment-timezone'
// moment.tz.setDefault('Asia/Seoul')
import VueMeta from 'vue-meta'

import { KAKAO_API_KEY } from '@/services/const.js'
window.Kakao.init(KAKAO_API_KEY)

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueMeta)

// Global RTL flag
Vue.mixin({
  data: globals
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
Vue.filter('timestampFormatDate', function (value) {
  if (value) {
    return moment(new Date(value)).format('YYYY-MM-DD HH:mm')
  }
})
Vue.filter('countDownTime', function (value, hours) {
  if (value) {
    const end = moment(value).add(hours, 'hours')
    const start = moment(new Date())
    const diff = moment.duration(end.diff(start))._milliseconds
    return diff > 0 ? diff : 0
  }
})

Vue.filter('timestampToAgo', function (value) {
  let returnValue = ''

  if (value) {
    const tDate = moment(value)._d
    const nDate = new Date()
    const diff = nDate.getTime() - tDate.getTime()
    // console.log(moment(value))
    // console.log(tDate, nDate, diff)

    let diffHour = diff / 1000 / 60 / 60
    diffHour = Math.round(diffHour)

    if (diffHour < 24) {
      if (diffHour <= 0) {
        let diffMin = diff / 1000 / 60
        diffMin = Math.round(diffMin)
        if (diffMin <= 0) {
          let diffSec = diff / 1000
          diffSec = Math.round(diffSec)
          if (diffSec === 0) {
            returnValue = '방금'
          } else {
            returnValue = diffSec + '초전'
          }
        } else {
          returnValue = diffMin + '분전'
        }
      } else {
        returnValue = diffHour + '시간전'
      }
    } else if (diffHour < 8760) {
      // returnValue = (tDate.getMonth() + 1) + '월 ' + tDate.getDate() + '일 ' + tDate.getHours() + '시 ' + tDate.getMinutes() + '분'
      returnValue = (tDate.getMonth() + 1) + '월 ' + tDate.getDate() + '일'
    } else {
      returnValue = tDate.getFullYear() + '년 ' + (tDate.getMonth() + 1) + '월 ' + tDate.getDate() + '일'
    }
  }

  return returnValue
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
