import LayoutAdmin from '@/layout/admin/LayoutAdmin'
import store from '@/store'

const requireAuth = () => (to, from, next) => {
  // console.log(from)
  // console.log(to)
  // console.log(next)

  if (store.getters.isAuthenticated && store.state.me && store.state.me.role === 'ROLE_ADMIN') {
    return next()
  } else {
    // return next('/home')
    return next()
  }
}

export default [{
  path: '/cms',
  component: LayoutAdmin,
  beforeEnter: requireAuth(),
  children: [{
    path: 'dashboard',
    name: 'cms-dashboard',
    component: () => import('@/components/cms/dashboard')
  }, {
    path: 'play',
    name: 'cms-play',
    component: () => import('@/components/cms/play/list')
  }, {
    path: 'playing',
    name: 'cms-playing',
    component: () => import('@/components/cms/playing/list')
  }, {
    path: 'product',
    name: 'cms-product',
    component: () => import('@/components/cms/product/list')
  }, {
    path: 'product/cart',
    name: 'cms-product-cart',
    component: () => import('@/components/cms/product/product_cart')
  }, {
    path: 'tag',
    name: 'cms-tag',
    component: () => import('@/components/cms/tag/list')
  }, {
    path: 'report',
    name: 'cms-report',
    component: () => import('@/components/cms/report/list')
  }, {
    path: 'email',
    name: 'cms-email',
    component: () => import('@/components/cms/message/email_send')
  }, {
    path: 'user',
    name: 'cms-user',
    component: () => import('@/components/cms/account/list')
  }, {
    path: 'account',
    name: 'cms-account2',
    component: () => import('@/components/cms/account/list-btable')
  }]
}]
