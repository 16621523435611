import LayoutService from '@/layout/social/LayoutService'
import store from '@/store'

export default [{
  path: '/',
  name: 'root',
  component: LayoutService,
  children: [{
    path: '',
    redirect: 'home'
  }, {
    path: 'home',
    name: 'Home',
    component: () => import('@/components/social/home/Home')
  }, {
    path: 'browse',
    name: 'Browse',
    component: () => import('@/components/social/browse/Browse')
  }, {
    path: 'tou',
    name: 'TOU',
    component: () => import('@/components/social/webview/tou'),
    meta: { unauthorized: true }
  }, {
    path: 'privacy',
    name: 'Privacy',
    component: () => import('@/components/social/webview/privacy'),
    meta: { unauthorized: true }
  }, {
    path: 'app',
    redirect: 'home'
  }, {
    path: 'cms',
    redirect: '/cms/dashboard'
  }]
}, {
  path: '/account',
  component: LayoutService,
  children: [{
    path: 'profile',
    name: 'Profile',
    component: () => import('@/components/social/account/Profile')
  }]
}, {
  path: '/play',
  component: LayoutService,
  children: [{
    path: 'new',
    name: 'playNew',
    component: () => import('@/components/social/play/PlayMaker')
  }, {
    path: 'edit/:id',
    name: 'playEdit',
    component: () => import('@/components/social/play/PlayMaker')
  }, {
    path: ':shareKey',
    name: 'playInfo',
    component: () => import('@/components/social/play/PlayView')
  }]
}, {
  path: '/p/:shareKey',
  component: LayoutService,
  children: [{
    path: '',
    name: 'playShare',
    component: () => import('@/components/social/play/PlayView'),
    meta: { unauthorized: true }
  }]
}, {
  path: '/search',
  component: LayoutService,
  children: [{
    path: '',
    redirect: 'Browse'
  }, {
    path: ':tagname',
    name: 'Search',
    component: () => import('@/components/social/search/Search')
  }]
}, {
  path: '/inbox',
  component: LayoutService,
  children: [{
    path: '',
    name: 'Inbox',
    component: () => import('@/components/social/inbox/Inbox')
  }, {
    path: ':channelUrl',
    name: 'InboxURL',
    component: () => import('@/components/social/inbox/Inbox')
  }]
}, {
  path: '/cart',
  component: LayoutService,
  children: [{
    path: '',
    name: 'Cart',
    component: () => import('@/components/social/commerce/Cart')
  }]
}, {
  path: '/login',
  name: 'login',
  component: () => import('@/components/social/auth/Login'),
  meta: { unauthorized: true }
}, {
  path: '/logout',
  name: 'Logout',
  beforeEnter: (to, from, next) => {
    store.dispatch('LOGOUT').then(() => {
      // next('/login')
      document.location.href = '/login'
    })
  }
}, {
  path: '/register',
  name: 'Register',
  component: () => import('@/components/social/auth/Register'),
  meta: { unauthorized: true }
}, {
  path: '/forgotPassword',
  name: 'Forgot',
  component: () => import('@/components/social/auth/PasswordForgot'),
  meta: { unauthorized: true }
}, {
  path: '/verificationEmail',
  name: 'Confirm',
  component: () => import('@/components/social/auth/EmailConfirm'),
  meta: { unauthorized: true }
}, {
  path: '/resetPassword',
  name: 'Change',
  component: () => import('@/components/social/auth/PasswordReset'),
  meta: { unauthorized: true }
}, {
  path: '/:username',
  component: LayoutService,
  children: [{
    path: '',
    name: 'User',
    component: () => import('@/components/social/account/My'),
    beforeEnter: (to, from, next) => {
      store.dispatch('USER', to.params.username)
        .then(() => {
          next()
        })
        .catch(error => {
          console.log(error.response)
          next('/login')
        })
    }
  }]
}]
