import layoutHelpers from '@/layout/helpers.js'
import * as numeral from 'numeral'

export default function () {
  return {
    // Public url
    publicUrl: process.env.BASE_URL,

    // Layout helpers
    layoutHelpers,

    // Check for RTL layout
    get isRtlMode () {
      return document.documentElement.getAttribute('dir') === 'rtl' ||
             document.body.getAttribute('dir') === 'rtl'
    },

    // Check if IE
    get isIEMode () {
      return typeof document.documentMode === 'number'
    },

    // Check if IE10
    get isIE10Mode () {
      return this.isIEMode && document.documentMode === 10
    },

    // Layout navbar color
    get layoutNavbarBg () {
      return 'navbar-theme'
    },

    // Layout sidenav color
    get layoutSidenavBg () {
      return 'sidenav-theme'
    },

    // Layout footer color
    get layoutFooterBg () {
      return 'footer-theme'
    },

    // Animate scrollTop
    scrollTop (to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return
      const start = element.scrollTop
      const change = to - start
      const startDate = +new Date()

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
      }

      const animateScroll = () => {
        const currentDate = +new Date()
        const currentTime = currentDate - startDate
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll)
        } else {
          element.scrollTop = to
        }
      }

      animateScroll()
    },

    youtubeParser (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      var match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },

    showToast (vm, msg, variant) {
      vm.$bvToast.toast(msg, {
        title: 'Playsongs',
        autoHideDelay: 3000,
        appendToast: true,
        toastClass: variant ? `bs4-toast bg-${variant}` : 'bs4-toast'
      })
    },
    goLogin (returnPath) {
      window.location.href = returnPath
    },
    goPlay (key) {
      if (key) {
        this.$router.push('/play/' + key).catch(error => {
          if (error.name === 'NavigationDuplicated') {
            location.reload()
          }
        })
      }
    },
    goUser (username) {
      this.$router.push('/' + username).catch(error => {
        if (error.name === 'NavigationDuplicated') {
          location.reload()
        }
      })
    },
    getPlayShareLink (shareKey) {
      return window.location.origin + '/p/' + shareKey + '?utm_source=web_button_share'
      // return 'https://social.playsongs.ai/p/' + shareKey + '?utm_source=web_button_share'
    },
    parseTags (tags) {
      if (tags) {
        return JSON.parse(tags)
      } else {
        return []
      }
    },
    carriageReturn (msg) {
      if (msg === null || msg === undefined || msg === '') {
        return ''
      } else {
        return msg.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    checkMobile () {
      if (/Android/i.test(navigator.userAgent)) {
        return 'android'
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return 'ios'
      } else {
        return 'other'
      }
    },
    numberFormat (price) {
      return numeral(price).format('0,0')
    },
    setSort (sort) {
      if (this.sort === sort) {
        this.orderBy = this.orderBy === 'DESC' ? 'ASC' : 'DESC'
      } else {
        this.sort = sort
        this.orderBy = 'DESC'
      }

      this.currentPage = 1
      this.loadItems()
    },
    getSortTemplate (sort) {
      if (this.sort === sort) {
        if (this.orderBy === 'DESC') {
          return '<i class="fas fa-angle-down ml-1"><i>'
        } else {
          return '<i class="fas fa-angle-up ml-1"><i>'
        }
      } else {
        return ''
      }
    }
  }
}
