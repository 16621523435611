<template>
  <nav class="layout-footer footer mt-5" :class="getLayoutFooterBg()">
    <div class="container container-p-x pb-3">
      <span class="pt-3 d-inline-block">&copy; 2020 YouBot</span>

      <span class="ml-5">
        <router-link to="/privacy" class="text-light"><small>개인정보처리방침</small></router-link>
        <span class="text-light">&#183;</span>
        <router-link to="/tou" class="text-light"><small>이용약관</small></router-link>
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'app-layout-footer',

  methods: {
    getLayoutFooterBg () {
      return `bg-${this.layoutFooterBg}`
    }
  }
}
</script>
