import LayoutWebView from '@/layout/social/LayoutWebView'

export default [{
  path: '/app',
  component: LayoutWebView,
  children: [{
    path: 'tou',
    name: 'app-tou',
    component: () => import('@/components/social/webview/tou'),
    meta: { unauthorized: true }
  }, {
    path: 'privacy',
    name: 'app-privacy',
    component: () => import('@/components/social/webview/privacy'),
    meta: { unauthorized: true }
  }, {
    path: 'withdrawal',
    name: 'app-withdrawal',
    component: () => import('@/components/social/webview/Withdrawal'),
    meta: { unauthorized: true }
  }, {
    path: 'user/report',
    name: 'user-report',
    component: () => import('@/components/social/webview/UserReport'),
    meta: { unauthorized: true }
  }]
}]
