import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import router from '../router'
import sendBird from '@/services/SendBird'

Vue.use(Vuex)

// 화면을 갱신할때 이 로컬 스토리지에 저장된 토큰을 axios 헤더에 설정하는 로직
const enhanceAccessToeken = () => {
  const { accessToken } = localStorage
  if (!accessToken) {
    axios.defaults.headers.common.Authorization = 'Guest guest'
    return
  }
  axios.defaults.headers.common.Authorization = accessToken
}
enhanceAccessToeken()

const clearAccessToeken = () => {
  axios.defaults.headers.common.Authorization = null
}

export default new Vuex.Store({
  state: {
    me: null,
    user: null,
    sbUser: null,
    accessToken: null,
    messages: [],
    channel: null,
    channels: null,
    channelUsers: [],
    play: { count: {}, owner: { activity: {} } },
    cartCount: null,
    carts: [],
    followings: [],
    recordsPerPage: 20,
    userReportData: {}
  },
  getters: {
    isAuthenticated: state => {
      // console.log('## Store Getters : isAuthenticated', localStorage.accessToken)

      if (!state.accessToken) {
        state.accessToken = localStorage.accessToken
      }

      return !!state.accessToken
    },
    isAdmin: (state, getters) => {
      return getters.isAuthenticated && state.me.role === 'ROLE_ADMIN'
    },
    me: state => {
      return state.me
    },
    user: (state) => {
      return state.user
    },
    earliestMessage: (state) => {
      return state.messages[0]
    },
    cartCount: (state) => {
      return state.cartCount
    }
  },
  mutations: {
    LOGIN (state, res) {
      state.user = null
      // console.log('STORE mutations ===> LOGIN')
      // console.log(res.headers.authorization)

      if (res.data.result.userId) {
        state.me = res.data.result
        state.accessToken = res.headers.authorization

        if (res.data.rememberMe) {
          localStorage.accessToken = res.headers.authorization
        }
        localStorage.email = state.me.email
        localStorage.me = btoa(encodeURIComponent(JSON.stringify(state.me)))
      } else {
        // 소셜 로그인 경우, 미회원이면 정보 저장해둠
        state.user = res.data.result
      }
    },
    LOGOUT (state) {
      // 토큰 정보 삭제
      // console.log('STORE.mutations - LOGOUT')
      state.user = null
      state.accessToken = null
      delete localStorage.accessToken
      delete localStorage.me
      delete localStorage.user

      // HTTP 요청 헤더값 제거
      clearAccessToeken()

      window.Kakao.Auth.logout()
    },
    SET_AUTH (state, dispatch) {
      // console.log('STORE.mutations - SET_AUTH')

      if (!state.me && localStorage.me) {
        try {
          state.me = JSON.parse(decodeURIComponent(atob(localStorage.me)))
        } catch (e) {
          console.error('store.SET_AUTH', e)
        }
      }
    },
    SET_ME (state, me) {
      state.me = me
      localStorage.me = btoa(encodeURIComponent(JSON.stringify(me)))
    },
    SET_USER (state, data) {
      state.user = data.result
    },
    SET_USER_REPORT (state, data) {
      state.userReportData = data.result
    },
    SET_SENDBIRD_USER: (state, user) => {
      // console.log('STORE mutations ===> SET_SENDBIRD_USER')
      state.sbUser = user
    },
    SET_CHANNELS: (state, channels) => {
      state.channels = channels
    },
    SET_CHANNEL: (state, channel) => {
      state.channel = channel
    },
    ADD_CHANNEL (state, channel) {
      state.channels.push(channel)
    },
    SET_CHANNEL_USERS: (state, channelUsers) => {
      state.channelUsers = channelUsers
    },
    SET_MESSAGES: (state, messages) => {
      state.messages = messages
    },
    SET_PLAY: (state, data) => {
      state.play = data.result
    },
    SET_CART_COUNT: (state, data) => {
      state.cartCount = data.result.count
    },
    SET_CARTS: (state, data) => {
      state.carts = data.result
    },
    ADD_CART: (state, product) => {
      state.cartCount++
    },
    REMOVE_CART: (state, product) => {
      state.cartCount--
    },
    SET_FOLLOWINGS (state, followings) {
      state.followings = followings
    },
    SET_PERPAGE (state, perPage) {
      state.recordsPerPage = perPage
    }
  },
  actions: {
    REGISTER ({ commit }, credentials) {
      const fd = new FormData()
      fd.append('name', credentials.name)
      fd.append('email', credentials.email)
      fd.append('password', credentials.password)
      fd.append('username', credentials.email.split('@')[0])
      fd.append('role', 'ROLE_USER')
      fd.append('childName', credentials.childName)
      fd.append('childGender', credentials.childGender)
      fd.append('childBirth', credentials.childBirth)

      if (credentials.imgUrl) fd.append('imgUrl', credentials.imgUrl)

      if (credentials.mirPush) fd.append('mirPush', credentials.mirPush)
      if (credentials.mirSms) fd.append('mirSms', credentials.mirSms)
      if (credentials.mirEmail) fd.append('mirEmail', credentials.mirEmail)

      if (credentials.regOs) fd.append('regOs', credentials.regOs)
      if (credentials.regType) fd.append('regType', credentials.regType)
      if (credentials.accessToken) fd.append('accessToken', credentials.accessToken)
      if (credentials.verified) fd.append('verified', credentials.verified)

      return axios.post('/api/auth/register', fd)
        .then(res => {
          if (res.data.success === 'success') {
            res.data.rememberMe = true
            commit('LOGIN', res)

            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
            enhanceAccessToeken()
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          // console.log(error.response)
          throw new Error(error.response.data.error)
        })
    },
    LOGIN ({ commit, dispatch }, credentials) {
      // console.log('STORE actions ===> LOGIN')
      const fd = new FormData()
      fd.append('email', credentials.email)
      fd.append('password', credentials.password)

      return axios.post('/api/auth/login', fd)
        .then(res => {
          if (res.data.success === 'success') {
            res.data.rememberMe = credentials.rememberMe
            commit('LOGIN', res)

            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
            enhanceAccessToeken()
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          // console.log(error.response)
          throw new Error(error.response.data.error)
        })
    },
    LOGIN_SOCIAL ({ commit, dispatch }, fd) {
      return axios.post('/api/auth/' + fd.get('service'), fd)
        .then(res => {
          if (res.data.success === 'success') {
            res.data.rememberMe = true
            commit('LOGIN', res)

            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
            enhanceAccessToeken()
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          // console.log(error.response)
          throw new Error(error.response.data.error)
        })
    },
    LOGOUT ({ commit }) {
      commit('LOGOUT')
    },
    AUTO_LOGIN ({ commit, state, dispatch }) {
      // console.log('STORE.actions - AUTO_LOGIN')
      return new Promise((resolve, reject) => {
        if (!state.me) {
          commit('SET_AUTH')
        }

        if (state.me) {
          // Sendbird 로그인
          dispatch('SENDBIRD_LOGIN')
          // 장바구니
          dispatch('CART_COUNT')

          resolve()
        } else {
          reject(new Error('state.me is null'))
        }
      })
    },
    SENDBIRD_LOGIN ({ commit, state }) {
      // console.log('STORE actions ===> SENDBIRD_LOGIN', state.me.userId)

      if (!state.sbUser) {
        sendBird
          .login(state.me.userId)
          .then(async user => {
            await commit('SET_SENDBIRD_USER', user)

            // Sendbird Connection Handler
            sendBird.addConnectionEventHandler()

            // console.info('SendBird => JOIN')
          })
          .catch(error => {
            this.message = error.message
          })
      }
    },
    SENDBIRD_CHANNELS ({ commit, state }) {
      const userId = state.me.userId
      sendBird
        .getGroupChannelList(userId)
        .then(channels => {
          channels.forEach((channel, index) => {
            const name = sendBird.getGroupChannelName(channel, userId)
            Vue.set(channel, 'name', name)
          })

          commit('SET_CHANNELS', channels)
        })
        .catch((error) => {
          this.toast = error
        })
    },
    SENDBIRD_MAKE_CHANNEL ({ commit, state }, users) {
      return sendBird
        .createGroupChannel(users)
        .then(groupChannel => {
          const name = sendBird.getGroupChannelName(groupChannel, state.me.userId)
          Vue.set(groupChannel, 'name', name)

          commit('ADD_CHANNEL', groupChannel)
          commit('SET_CHANNEL', groupChannel)
        })
        .catch(error => {
          this.message = error.message
        })
    },
    addMessage: ({ commit, state }, message) => {
      commit('SET_MESSAGES', state.messages.concat(message))
    },
    addMessages: ({ commit, state }, messages) => {
      commit('SET_MESSAGES', messages.concat(state.messages))
    },
    addChannelUser: ({ commit, state }, user) => {
      commit('SET_CHANNEL_USERS', state.channelUsers.concat(user))
    },
    removeChannelUser: ({ commit, state }, user) => {
      commit('SET_CHANNEL_USERS', state.channelUsers.filter(it => it.userId !== user.userId))
    },
    USER ({ commit }, username) {
      const params = {
        username: username
      }

      return axios.get('/api/user/info', { params: params })
        .then(({ data }) => {
          commit('SET_USER', data)
        })
    },
    USER_REPORT ({ commit }, userId) {
      var params = {
        userId: userId
      }
      return axios.get('/api/user/report', { params: params })
        .then(({ data }) => {
          commit('SET_USER_REPORT', data)
        })
    },
    GET_PLAY ({ commit, state }, shareKey) {
      return axios.get('/api/play/' + shareKey)
        .then(({ data }) => {
          commit('SET_PLAY', data)
        })
    },
    CART_COUNT ({ commit, state }) {
      return axios.get('/api/cart/count')
        .then(res => {
          if (res.data.success === 'success') {
            commit('SET_CART_COUNT', res.data)
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          throw new Error(error.response.data.error)
        })
    },
    CART_LIST ({ commit, state }) {
      return axios.get('/api/cart/list')
        .then(res => {
          if (res.data.success === 'success') {
            commit('SET_CARTS', res.data)
          } else {
            throw new Error(res)
          }
        })
        .catch(error => {
          console.log(error)
          throw new Error(error.response.data.error)
        })
    },
    ADD_CART ({ commit, state, dispatch }, product) {
      const fd = new FormData()
      fd.append('count', product.count)

      return axios.post('/api/cart/' + product.productId, fd)
        .then(res => {
          if (res.data.success === 'success') {
            commit('ADD_CART', res.data)
            dispatch('CART_COUNT')
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          throw new Error(error.response.data.error)
        })
    },
    REMOVE_CART ({ commit, state }, product) {
      return axios.delete('/api/cart/' + product.productId)
        .then(res => {
          if (res.data.success === 'success') {
            commit('REMOVE_CART', res.data)
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          throw new Error(error.response.data.error)
        })
    },
    REMOVE_PLAY ({ state }, playId) {
      return axios.delete('/api/play/' + playId)
        .then(res => {
          if (res.data.success === 'success') {
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          throw new Error(error.response.data.error)
        })
    },
    FOLLOWING ({ state, commit }) {
      const params = {
        userId: state.me.userId
      }

      return axios.get('/api/user/following/list', { params: params })
        .then(res => {
          if (res.data.success === 'success') {
            commit('SET_FOLLOWINGS', res.data.result)
          } else {
            throw new Error(res.data.error)
          }
        })
        .catch(error => {
          throw new Error(error.response.data.error)
        })
    }
  }
})
