<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" fixed="top" class="layout-navbar align-items-lg-center container-p-x">
    <div class="container">
      <!-- Brand -->
      <b-navbar-brand @click.prevent="goHome()" class="py-0 cursor-pointer">
        <img src="https://timeattack-dev.s3.ap-northeast-2.amazonaws.com/common/timeattack-i-i-128.png" class="ml-2 ui-w-40">
      </b-navbar-brand>

      <!-- Navbar toggle -->
      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse is-nav id="app-layout-navbar">
        <!--
        <b-navbar-nav class="align-items-lg-center">
          <b-nav-item to="/" :exact="true">Home</b-nav-item>
          <b-nav-item to="/browse" :exact="true">Browse</b-nav-item>
          <b-nav-item to="/my" :exact="true">My</b-nav-item>
        </b-navbar-nav>
        -->

        <b-navbar-nav class="align-items-lg-center ml-auto" v-if="isAuthenticated">
          <li class="nav-item mr-lg-4 with-icon">
            <label class="nav-link navbar-search-box position-relative" :class="{ 'active': searchExpanded }">
              <!-- i class="ion ion-ios-search navbar-icon align-middle"></i -->
              <i class="icon-nav icon-search"></i>
              <span class="navbar-search-input">
                <b-input class="mx-2"
                  placeholder="Search..."
                  autocomplete="off"
                  v-model="searchKeyword"
                  @focus.native="searchExpanded=true"
                  @keydown.down="down"
                  @keydown.up="up"
                  @keydown.enter="hit"
                  @keydown.esc="reset"
                  @keyup.enter="down"
                  @blur="reset"
                  @input="updateQuery" />
                <a href="javascript:void(0)" class="navbar-search-cancel" @click.stop.prevent="resetInput">&times;</a>
              </span>

              <div class="dropdown-menu" :class="{ 'd-block': hasItems }" :style="{left: isRtlMode ? 'auto' : 0, right: isRtlMode ? 0 : 'auto'}">
                <a class="dropdown-item media" href="javascript:void(0)" v-for="(item, index) in items" :key="index" :class="activeClass(index)" @mousedown="hit" @mousemove="setActive(index)">
                  <img :src="item.imgUrl" class="d-block ui-w-30 rounded-circle" alt>
                  <div class="media-body ml-2">
                    <div class="name small" v-text="item.name"></div>
                    <div v-if="item.type === 'tag'" class="text-muted text-tiny">관련 이벤트 {{ item.count }}개</div>
                    <div v-if="item.type === 'user'" class="text-muted text-tiny">놀이&middot;참여 {{ item.count }}개</div>
                  </div>
                </a>
              </div>
              <div v-if="showNoResult" class="dropdown-menu" :class="{ 'd-block': !hasItems }">
                <div class="dropdown-item">검색 결과가 없습니다.</div>
              </div>
            </label>
          </li>

          <b-nav-item to="/" :exact="true" class="with-icon mr-lg-4" :active="$route.name === 'Home'">
            <i class="icon-nav icon-home"></i>
          </b-nav-item>

          <b-nav-item to="/browse" :exact="true" class="with-icon mr-lg-4" :active="$route.name === 'Browse'">
            <i class="icon-nav icon-browse"></i>
          </b-nav-item>

          <b-nav-item to="/inbox" :exact="true" class="with-icon mr-lg-4" :active="$route.name === 'Inbox'">
            <i class="icon-nav icon-inbox"></i>
          </b-nav-item>

          <b-nav-item to="/play/new" :exact="true" class="with-icon mr-lg-3" :active="$route.name === 'playNew'">
            <i class="icon-nav icon-create"></i>
          </b-nav-item>

          <!-- Divider -->
          <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

          <b-nav-item to="/cart" class="with-icon mr-lg-4">
            <i class="icon-nav icon-cart"></i>
            <span class="badge badge-danger indicator">{{ cartCount }}</span>
            <span class="d-lg-none align-middle">&nbsp; Cart</span>
          </b-nav-item>

          <b-nav-item :to="'/' + currentUser.username" class="mr-0" link-classes="pr-0" v-if="isAuthenticated">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <img :src="currentUser.imgUrl" alt class="d-block ui-w-30 rounded-circle">
            </span>
          </b-nav-item>

          <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user" v-if="isAuthenticated">
            <b-dd-item :to="'/' + currentUser.username"><i class="ion ion-ios-basketball text-light"></i> &nbsp; 나의 이벤트</b-dd-item>
            <b-dd-item to="/account/profile"><i class="ion ion-ios-settings text-light"></i> &nbsp; 설정</b-dd-item>
            <b-dd-item v-if="isAdmin" to="/cms/dashboard"><i class="ion ion-ios-lock text-light"></i> &nbsp; 관리자</b-dd-item>
            <b-dd-divider />
            <b-dd-item to="/logout"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; 로그아웃</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav class="align-items-lg-center ml-auto" v-if="!isAuthenticated">
          <b-nav-item class="mr-lg-3" v-if="!isAuthenticated">
            <router-link :to="'/login'">로그인</router-link>
          </b-nav-item>

          <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

          <b-nav-item class="mr-lg-3" v-if="!isAuthenticated">
            <router-link :to="'/register'">회원가입</router-link>
          </b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </div>
  </b-navbar>
</template>

<style scoped>
.layout-navbar-fixed .layout-navbar {
  z-index: 1000;
}
.nav-item.with-icon .nav-link {
  padding: 0;
  line-height: 0;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Axios from 'axios'
import VueTypeahead from 'vue-typeahead'

// Set up $hhtp client (required by vue-typeahead)
Vue.prototype.$http = Axios

export default {
  extends: VueTypeahead,
  name: 'app-layout-navbar',
  props: {
  },
  data: () => ({
    showNoResult: false,
    searchExpanded: false,
    searchKeyword: '',
    src: '/api/search/keyword',
    limit: 5,
    minChars: 1
  }),

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'isAdmin',
      'me',
      'cartCount'
    ])
  },

  created () {
    if (this.isAuthenticated) {
      this.currentUser = this.me
    }
  },

  watch: {
    '$route' (to, from) {
      this.searchExpanded = false
    }
  },

  methods: {
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },
    onEnter () {
      this.setActive(0)
    },
    updateQuery () {
      this.query = this.searchKeyword
      this.update()

      if (this.searchKeyword.length === 0) {
        this.showNoResult = false
      }
    },
    onHit (item) {
      if (item) {
        this.searchKeyword = item.name

        if (item.type === 'tag') {
          this.$router.push('/search/' + item.path)
        } else {
          this.$router.push('/' + item.path)
        }

        this.resetInput()
      }
    },
    resetInput () {
      this.showNoResult = false
      this.searchExpanded = false
      this.searchKeyword = ''
      this.reset()
    },
    prepareResponseData (data) {
      if (data.length > 0) {
        this.showNoResult = false
      } else {
        this.showNoResult = true
      }

      return data
    },
    goHome () {
      if (this.$route.name === 'Home') {
        this.scrollTop(0, 0)
      } else {
        this.$router.push('/home')
      }
    }
  }
}
</script>
